
<script>

import Storage from "./../../api/storage";
import RadialProgressBar from 'vue-radial-progress'
export default {
    components: {
      // RadialProgressBar
    },
    computed:{
      files(){
        return this.$store.getters["upload/files"];
      },
      parent_id(){
        return this.$store.getters[this.$store.getters["state/state"]+"/parent_id"];
      },
      allDone(){
        return this.$store.getters["upload/files"].findIndex((v)=>v.done == false);
      }
    },
    data() {
        return {
            folderName: "Folder New",
            invalidFolderName : "Your account or Email is incorrect.",
            validFolderName : "",
            stateFolderName : true,
            hide : false
        };
    },
    methods:{
      getPercent(file){
        var percent = (parseInt(file.uploaded) / parseInt(file.total)) * 100
        return percent
      },
      getSize(bytes){
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
      },
      getImage(filename){
        var storageUtils = new Storage();
        return storageUtils.getImage(filename.toLowerCase().substr(filename.lastIndexOf('.') + 1))
      },
      showHide(){
        this.hide = !this.hide
      },
      close(){
        this.$store.commit("upload/CLEAR");
        document.getElementById("uploadLoading").style.display = "none";
        document.getElementById("fileUpload").value = null
        document.getElementById("folderUpload").value = null
      },
      remove(id){
        if(this.files.length <= 1){
          this.$store.commit("upload/CLEAR");
          document.getElementById("uploadLoading").style.display = "none";
          document.getElementById("fileUpload").value = null
          document.getElementById("folderUpload").value = null
        }
        this.$store.commit("upload/REMOVE",id);
      },
      getColor(status){
        if(status == 4){
          return "danger";
        }else if(status == 3){
          return "success";
        }else{
          return "primary";
        }
      }
    }
};
</script>
<template>
    <div id="uploadLoading">
      <div class="d-flex justify-content-between align-content-center paddings">
          <div v-if="allDone != -1" class="text-title-uploaded">
            <span class="default-normal-size black-color">Uploading</span>
            <div class="dot-flashing"></div>
          </div>
          <div v-else class="text-title-uploaded">
            <span class="default-normal-size black-color">Complete</span>
          </div>
          <div class="d-flex">
              <span class="cursor-pointer mr-2" @click="showHide">
              <img v-if="!hide" src="@/assets/images/icon/chevDown.svg" alt width="100%" />
              <img v-else src="@/assets/images/icon/chevDown.svg" alt width="100%" class="rotate-180"/>
            </span>
            <span class="cursor-pointer" @click="close" >
              <img src="@/assets/images/icon/close-secure.svg" data-toggle="tooltip" title="Close all" alt width="100%" />
            </span>
          </div>
      </div>
      <div v-if="!hide">
        <!-- <div v-if="allDone != -1">
          <span>in progress..</span>
        </div>
        <div v-else>
          <span>files success</span>
        </div> -->
        <div class="info-upload-notif">
          <div class="horizontal-modal-line"></div>
          <!-- <div class="px-4 d-flex" v-if="allDone != -1">
            <p class="font-size-12 default-text-style"><span class="font-size-12 letter05">Starting upload...</span></p>
          </div> -->
          <div class="d-flex align-content-center flex-column mb-4 px-4" v-for="file in files" :key="file.id">
            <div class="d-flex justify-content-start align-content-center">
              <div class="d-flex justify-content-center align-items-center">
                <img  :src="require(`@/assets/images/icon/Folder.svg`)" v-if="file.isFolder" alt height="100%" class="img-uploaded-status"/>
                <img  :src="require(`@/assets/images/icon/${getImage(file.name)}`)" v-else alt height="100%" class="img-uploaded-status"/>
              </div>
              <div class="d-flex flex-column details-uploaded-status">
                <!-- <pre>{{file}}</pre> -->
                <div class="d-flex align-item-center justify-content-between">
                  <!-- <div v-if="file.isFolder" class="d-flex justify-content-start">
                    <span class="text-info-uploaded-folder">{{file.name}}</span>
                    <span class="ml-2">{{file.uploaded}} Of {{file.total}}</span>
                  </div> -->
                  <div class="text-info-uploaded">
                    <span>{{file.name}}</span>
                  </div>
                  <div class="cursor-pointer" @click="remove(file.id)">
                    <img
                      src="@/assets/images/icon/close-secure.svg"
                      alt=""
                      data-toggle="tooltip"
                      :title="file.done ? 'Close' : 'Cancel'">
                  </div>
                </div>
                <div v-if="!file.done && file.status == 2" class="linear-progress my-2">
                  <b-progress v-if="file.isFolder" :value="getPercent(file)" :max="100"></b-progress>
                  <b-progress v-else :value="file.percentage" :max="100"></b-progress>
                </div>
                <div v-else-if="!file.done && file.status == 3" class="linear-activity">
                  <div class="indeterminate"></div>
                </div>
                <div v-else class="linear-progress my-2">
                  <b-progress :value="file.percentage" :max="100"></b-progress>
                </div>
                <!-- <div class="linear-activity">
                  <div class="indeterminate"></div>
                </div> -->
                <div v-if="file.isFolder" class="d-flex justify-content-between">
                  <span v-if="file.done && file.status == 3" class="green-color text-info-status">Uploaded</span>
                  <span v-else-if="file.status == 4" class="text-info-status red-color" data-toggle="tooltip" :title="file.error.data ? file.error.data.data : file.error">{{file.error.data ? file.error.data.data : file.error}}</span>
                  <span v-else>{{getPercent(file)}} %</span>
                  <span class="grey-bold-color text-second-info-status">{{file.uploaded}} of {{file.total}}</span>
                </div>
                <div v-else class="d-flex justify-content-between">
                  <span v-if="file.done && file.status == 3" class="green-color text-info-status">Uploaded</span>
                  <span v-else-if="file.status == 4" class="text-info-status red-color" data-toggle="tooltip" :title="file.error.data ? file.error.data.data : file.error">{{file.error.data ? file.error.data.data : file.error}}</span>
                  <span v-else>{{file.percentage}} %</span>
                  <span v-show="file.isFolder != true" class="grey-bold-color text-second-info-status">{{getSize(file.size)}}</span>
                </div>
              </div>
            </div>
            <!-- <div class="d-flex justify-content-between align-content-center">
              <div class="d-flex align-items-center justify-items-center file-name-secure">
                <img  :src="require(`@/assets/images/icon/Folder.svg`)" v-if="file.isFolder" alt height="100%" />
                <img  :src="require(`@/assets/images/icon/${getImage(file.name)}`)" v-else alt height="100%" />
                <p class="font-size-16 px-2 mb-0 notif-btm-text">{{file.name}}</p>
                <p class="font-size-16 px-2 mb-0 text-muted notif-btm-text"  v-if="file.isFolder">{{file.uploaded}} Of {{file.total}}</p>
                <p class="font-size-16 px-2 mb-0 text-muted notif-btm-text">{{file.percentage}}</p>
              </div>
              <div class="d-flex justify-content-end">
                <div>
                  <radial-progress-bar 
                    :diameter="16"
                    :completed-steps="file.percentage"
                    :total-steps="100"
                    :strokeWidth="3"
                    :innerStrokeWidth="3"
                    startColor="#2098D4"
                    stopColor="#2098D4"
                    innerStrokeColor="#fff"
                    v-show="!file.done && file.status == 2">
                  </radial-progress-bar>
                </div>
                <div v-show="!file.done && file.status == 3" class="spinner-border hw-16 font-size-14 text-primary" role="status">
                </div>
                <div v-show="file.done && file.status == 3">
                  <img src="@/assets/images/icon/success-secure.svg" alt=""  data-toggle="tooltip" title="Uploaded successfully">
                </div>
                <div v-show="file.status == 4">
                  <img src="@/assets/images/icon/error-secure.svg" class="cursor-pointer" alt="" :title="file.error.data ? file.error.data.data : file.error">
                </div>
                <div class="cursor-pointer ml-3" @click="remove(file.id)">
                  <img src="@/assets/images/icon/close-secure.svg" alt="">
                </div>
              </div>
            </div>
            <div class="linear-progress">
                <b-progress :value="file.percentage" :max="100"></b-progress>
                
                <div class="linear-activity">
                    <div class="indeterminate"></div>
                </div>
            </div> -->
          </div>
          <!-- <div class="d-flex align-content-center flex-column mb-4 px-4" v-for="file in files" :key="file.id">
            <div class="d-flex justify-content-between align-content-center">
              <div class="d-flex align-items-center justify-items-center file-name-secure">
                <img  :src="require(`@/assets/images/icon/Folder.svg`)" v-if="file.isFolder" alt height="100%" />
                <img  :src="require(`@/assets/images/icon/${getImage(file.name)}`)" v-else alt height="100%" />
                <p class="font-size-16 px-2 mb-0 notif-btm-text">{{file.name}}</p>
                <p class="font-size-16 px-2 mb-0 text-muted notif-btm-text"  v-if="file.isFolder">{{file.uploaded}} Of {{file.total}}</p>
                <p class="font-size-16 px-2 mb-0 text-muted notif-btm-text">{{file.percentage}}</p>
              </div>
              <div class="d-flex justify-content-end">
                <div>
                  <radial-progress-bar 
                    :diameter="16"
                    :completed-steps="file.percentage"
                    :total-steps="100"
                    :strokeWidth="3"
                    :innerStrokeWidth="3"
                    startColor="#2098D4"
                    stopColor="#2098D4"
                    innerStrokeColor="#fff"
                    v-show="!file.done && file.status == 2">
                  </radial-progress-bar>
                </div>
                <div v-show="!file.done && file.status == 3" class="spinner-border hw-16 font-size-14 text-primary" role="status">
                </div>
                <div v-show="file.done && file.status == 3">
                  <img src="@/assets/images/icon/success-secure.svg" alt=""  data-toggle="tooltip" title="Uploaded successfully">
                </div>
                <div v-show="file.status == 4">
                  <img src="@/assets/images/icon/error-secure.svg" class="cursor-pointer" alt="" :title="file.error.data ? file.error.data.data : file.error">
                </div>
                <div class="cursor-pointer ml-3" @click="remove(file.id)">
                  <img src="@/assets/images/icon/close-secure.svg" alt="">
                </div>
              </div>
            </div>
            <div class="linear-progress">
                  <b-progress :value="file.percentage" :max="100"></b-progress>
                  
                  <div class="linear-activity">
                      <div class="indeterminate"></div>
                  </div>
              </div>
          </div> -->
        </div>
      </div>
    </div>
</template>

<style scoped>

</style>