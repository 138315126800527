<template>
  <div id="encryptLoading">
    <div class="d-flex justify-content-between align-content-center px-4">
        <p class="mb-4 bold-normal default-text-style color-dark">Encrypting {{encryptProcess.length}} item</p>
        <div class="d-flex">
            <span class="cursor-pointer" @click="showHideEncrypt()">
            <img src="@/assets/images/icon/chevDown.svg" alt width="100%" />
        </span>
        <span class="cursor-pointer ml-3" @click="close()">
            <img src="@/assets/images/icon/close-secure.svg" alt="" />
        </span>
        </div>
    </div>
    <div v-if="!hideEncrypt">
        <div class="info-encrypt-notif">
          <!-- <pre>{{firebaseProcess}}</pre> -->
          <div class="horizontal-modal-line"></div>
          <div class="d-flex justify-content-between align-content-center flex-column mb-4 px-4" v-for="file in encryptProcess" :key="file._id">
            <div class="d-flex justify-content-between align-content-center">
              <div class="d-flex align-items-center justify-items-center file-name-secure">
                <img :src="require(`@/assets/images/icon/${getImage(file.file_name)}`)" alt height="100%" />
                <p class="font-size-16 px-2 mb-0 notif-btm-text" data-toggle="tooltip" :title="file.file_name">{{file.file_name}}</p>
              </div>
              <div class="d-flex justify-content-end">
                <div v-show="file.encrypt.code == 1" class="spinner-border hw-16 font-size-14 text-primary" role="status">
                  <!-- <span class="sr-only">Loading...</span> -->
                </div>
                <div v-show="file.encrypt.code == 2">
                  <img src="@/assets/images/icon/success-secure.svg" alt="" data-toggle="tooltip" title="Encrypted successfully">
                </div>
                <div v-show="file.encrypt.code == 3">
                  <img src="@/assets/images/icon/error-secure.svg" alt="" data-toggle="tooltip" title="Encryption failed">
                </div>
                <div class="cursor-pointer ml-3" @click="remove(file._id)">
                  <img src="@/assets/images/icon/close-secure.svg" alt="">
                </div>
              </div>
            </div>
              <!-- <div>
                <div id="progress-encrypt">
                  <b-avatar variant="success" size="2rem" v-show="file.encrypt.code == 2"><i class="bx bx-check font-size-18"></i></b-avatar>
                  <b-avatar variant="danger" size="2rem" v-show="file.encrypt.code == 3"><i class="bx bx-info-circle font-size-18"></i></b-avatar>
                  <div v-show="file.encrypt.code == 1" class="spinner-border font-size-18 text-primary" role="status">
                  </div>
                </div>
                <div class="avatar-xs" @click="remove(file._id)">
                    <span class="avatar-title rounded-circle"><i class="bx bx-x font-size-18"></i></span>
                </div>
              </div>
            <p v-if="file.encrypt.code == 1" class="text-muted ml-2">Encryption is in progress</p>
            <p v-if="file.encrypt.code == 2" class="text-muted ml-2">Encrypted successfully</p>
            <p v-if="file.encrypt.code == 3" class="text-muted ml-2">Encryption failed</p> -->
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import Storage from "../../api/storage";

export default {
    data() {
        return {
            hideEncrypt: false,
            percentage: 0
        }
    },
    computed: {
        firebaseProcess() {
            return this.$store.getters["encrypt/notifEncrypt"]
        },
        encryptProcess() {
            return this.$store.getters["encrypt/progress"]
        }
    },
    methods: {
        close() {
            this.$store.commit("encrypt/CLEAR")
            this.$store.commit("encrypt/RESET_PROGRES")
            document.getElementById("encryptLoading").style.display = "none"
        },
        showHideEncrypt(){
            this.hideEncrypt = !this.hideEncrypt
        },
        remove(id){
            if(this.encryptProcess.length <= 1){
                this.$store.commit("encrypt/CLEAR");
                document.getElementById("encryptLoading").style.display = "none";
            }
            this.$store.commit("encrypt/REMOVE",id);
        },
        getImage(filename){
            var storageUtils = new Storage();
            return storageUtils.getImage(filename.substr(filename.lastIndexOf('.') + 1))
        }
    }
}
</script>

<style>

</style>