<template>
    <div id="side-bar-menu-2" class="">
        <div class="sidebar-two-container">
            <div class="side-bar-search-wg">
                <img src="@/assets/images/icon/search-black.svg" alt="" height="100%">
                <input
                    @focus="focusSearch()"
                    @focusout="focusOutSearch()"
                    v-model="search" type="text" placeholder="Search">
            </div>
            <img src="@/assets/images/icon/chevron-right-angle-white.svg" id="expand-side-btn" @click="expandSidebar()" alt="">
        </div>
        <div class="side-bar-filter">
            <div class="side-bar-filter-group">
                <div v-show="isParticipant == false" class="wg-static-search" id="filter-owner">
                    <span @click="ownerWg()" class="cursor-pointer">Owner</span>
                    <img @click="showAllWg()" v-show="isOwner == true" src="@/assets/images/icon/Circle-Xmark.svg" class="ml-1 cursor-pointer" alt="">
                </div>
                <div v-show="isOwner == false" class="mr-2 wg-static-search"  id="filter-participant">
                    <span @click="participantWg()" class="cursor-pointer">Participant</span>
                    <img @click="showAllWg()" v-show="isParticipant == true" src="@/assets/images/icon/Circle-Xmark.svg" class="ml-1 cursor-pointer" alt="">
                </div>
            </div>
        </div>
        <div v-if="workList.length > 0" class="side-bar-wg">
        <!-- <div v-if="testListWg.length > 0" class="side-bar-wg"> -->
            <div v-if="filteredWorkgroupPrimary.length <= 0 && filteredWorkgroupEnterprise.length <= 0 && searchFocus == true" class="empty-wg">
                <img src="@/assets/images/new-not-found-wg.svg" class="rotate-0" alt="">
                <span class="d-flex justify-content-center align-items-center">We can’t find the workgroup,</span>
                <span class="d-flex justify-content-center align-items-center">check or try another word.</span>
            </div>
            <div v-if="filteredWorkgroupPrimary.length > 0 || filteredWorkgroupEnterprise.length > 0 && searchFocus == true" id="primary-wg-toggle" @click="togglePrimaryWg()" class="side-bar-wg-title">
                <span>Primary</span>
                <img id="primary-wg-img-toggle" src="@/assets/images/icon/chevron-down-angel-grey.svg" class="mr-1 rotate-180" alt="">
            </div>
            <ul id="primary-wg" class="sub-new-wg active">
                <div v-if="filteredWorkgroupPrimary.length <= 0 && searchFocus == false" class="empty-sub-wg">
                    <span>Your primary workgroup will show up here.</span>
                </div>
                <!-- <li v-for="work in filteredWorkgroupPrimary" :key="work.id" @click="openWorkgroup()"> -->
                <li v-for="work in filteredWorkgroupPrimary" :key="work._id">
                    <div class="side-bar-link-ref list-wg-user cursor-pointer"
                        :class="state == 'workgroup' && selectedWorkgroup._id == work._id ? 'active' : ''">
                        <div
                            @click="openWorkgroup(work)"
                            class="wg-info"
                            data-toggle="tooltip"
                            :title="`${work.title}. Primary`">
                            <span class="title-wg">{{work.title}}</span>
                            <span class="sub-title-wg">Primary</span>
                        </div>
                        <div class="wg-stars">
                            <img v-if="work.favorite != false" src="@/assets/images/icon/yellow-stars.svg" class="img-wg-fav" alt="" height="100%">
                            <img @click="toggleDropdown(work._id)" src="@/assets/images/icon/ellipsis-horizontal.svg" class="img-wg-more" alt="" height="100%">
                            <div class="dropdown-more-scnd-side" :class="{ show: activeDropdown === work._id }">
                                <div v-if="work.favorite == false" class="list-item" @click="addFavorite(work._id)">
                                    <img src="@/assets/images/icon/white-stars.svg" alt height="100%" />
                                    <span class="ml-2 clr-black-262a2c">Add to Favorite</span>
                                </div>
                                <div v-else class="list-item" @click="removeFavorite(work._id)">
                                    <img src="@/assets/images/icon/yellow-stars.svg" alt height="100%" />
                                    <span class="ml-2 clr-black-262a2c">Unfavorite</span>
                                </div>
                                <div class="list-item" @click="work.owner_id == userId ? deleteWorkgroup(work._id) : leaveWorkgroup(work._id)">
                                    <img src="@/assets/images/icon/red-trash.svg" alt height="100%" />
                                    <span class="ml-2 clr-red-d42020">{{work.owner_id == userId ? 'Delete Workgroup' : 'Leave Workgroup'}}</span>
                                </div>
                            </div>
                            <span>Y</span>
                        </div>
                    </div>
                </li>
            </ul>
            <div v-if="filteredWorkgroupPrimary.length > 0 || filteredWorkgroupEnterprise.length > 0 && searchFocus == true" class="devided-wg">
                <hr class="">
            </div>
            <div v-if="filteredWorkgroupPrimary.length > 0 || filteredWorkgroupEnterprise.length > 0 && searchFocus == true" id="enterprise-wg-toggle" @click="toggleEnterpriseWg()" class="side-bar-wg-title">
                <span>Enterprise</span>
                <img id="enterprise-wg-img-toggle" src="@/assets/images/icon/chevron-down-angel-grey.svg" class="mr-1 rotate-180" alt="">
            </div>
            <ul id="enterprise-wg" class="sub-new-wg active">
                <div v-if="filteredWorkgroupEnterprise.length <= 0 && searchFocus == false" class="empty-sub-wg">
                    <span>Your enterprise workgroup will show up here.</span>
                </div>
                <li v-for="work in filteredWorkgroupEnterprise" :key="work._id">
                    <div class="side-bar-link-ref list-wg-user cursor-pointer"
                        :class="state == 'workgroup' && selectedWorkgroup._id == work._id ? 'active' : ''">
                        <div
                            @click="openWorkgroup(work)"
                            class="wg-info"
                            
                            data-toggle="tooltip"
                            :title="`${work.title}. ${work.enterprise ? work.enterprise.company_name : ''}`">
                            <span class="title-wg">{{work.title}}</span>
                            <span class="sub-title-wg">{{work.enterprise ? work.enterprise.company_name : ''}}</span>
                        </div>
                        <!-- <div
                            class="wg-info"
                            data-toggle="tooltip"
                            title="abcdefghijklmnopqrstuvwxyz. abcdefghijklmnopqrstuvwxyz">
                            <span class="title-wg">abcdefghijklmnopqrstuvwxyz</span>
                            <span class="sub-title-wg">abcdefghijklmnopqrstuvwxyz</span>
                        </div> -->
                        <div class="wg-stars">
                            <img v-if="work.favorite != false" src="@/assets/images/icon/yellow-stars.svg" class="img-wg-fav" alt="" height="100%">
                            <img @click="toggleDropdown(work._id)" src="@/assets/images/icon/ellipsis-horizontal.svg" class="img-wg-more" alt="" height="100%">
                            <div class="dropdown-more-scnd-side" :class="{ show: activeDropdown === work._id }">
                                <div v-if="work.favorite == false" class="list-item" @click="addFavorite(work._id)">
                                    <img src="@/assets/images/icon/white-stars.svg" alt height="100%" />
                                    <span class="ml-2 clr-black-262a2c">Add to Favorite</span>
                                </div>
                                <div v-else class="list-item" @click="removeFavorite(work._id)">
                                    <img src="@/assets/images/icon/yellow-stars.svg" alt height="100%" />
                                    <span class="ml-2 clr-black-262a2c">Unfavorite</span>
                                </div>
                                <div class="list-item" @click="work.owner_id == userId ? deleteWorkgroup(work._id) : leaveWorkgroup(work._id)">
                                    <img src="@/assets/images/icon/red-trash.svg" alt height="100%" />
                                    <span class="ml-2 clr-red-d42020">{{work.owner_id == userId ? 'Delete Workgroup' : 'Leave Workgroup'}}</span>
                                </div>
                            </div>
                            <span>Y</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div v-if="workList.length <= 0" class="side-bar-wg">
        <!-- <div v-if="testListWg.length <= 0" class="side-bar-wg"> -->
            <div v-if="searchFocus == false" class="empty-wg">
                <img src="@/assets/images/new-empty-workgroup.svg" alt="">
                <span class="mt-2">Add a workgroup</span>
                <span>to start collaborating.</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            search: '',
            searchFocus: false,
            isOwner: false,
            isParticipant: false,
            activeDropdown: null,
            // testListWg2: [],
            // testListWg: [
            //     {
            //         id: 1,
            //         title: 'abcdefghijklmnopqrstuvwxyz',
            //         enterprise: 'abcdefghijklmnopqrstuvwxyz',
            //         isEnterprise: true,
            //         status: 'Owner',
            //         favourite: true
            //     },
            //     {
            //         id: 2,
            //         title: 'Primary Group',
            //         enterprise: 'Primary',
            //         isEnterprise: false,
            //         status: 'Participant',
            //         favourite: false
            //     },
            //     {
            //         id: 3,
            //         title: 'Enterprise Group',
            //         enterprise: 'Enterprise Dev Ops',
            //         isEnterprise: true,
            //         status: 'Participant',
            //         favourite: false
            //     },
            //     {
            //         id: 4,
            //         title: 'TWG',
            //         enterprise: 'Primary',
            //         isEnterprise: false,
            //         status: 'Participant',
            //         favourite: true
            //     },
            //     {
            //         id: 5,
            //         title: 'abcdefghijklmnopqrstuvwxyz',
            //         enterprise: 'Primary',
            //         isEnterprise: false,
            //         status: 'Participant',
            //         favourite: true
            //     },
            // ]
        }
    },
    mounted() {
        window.addEventListener("click", this.handleOutsideClick)
    },
    beforeDestroy() {
        window.removeEventListener("click", this.handleOutsideClick);
    },
    computed: {
        state(){
            return this.$store.getters['state/state'];
        },
        selectedWorkgroup(){
            return this.$store.getters['workgroup/workgroup'];
        },
        userId() {
            return this.$store.getters['user/id']
        },
        workList() {
            var lists = this.$store.getters['workgroup/workgroups'];
            // return lists.reverse()
            var user_id = this.$store.getters['user/id']
            return lists
            // return lists.reverse().filter((item,index)=>index <=5);
        },
        filteredWorkgroupPrimary(){
            if(this.isOwner == true){
                return this.workList.filter((workgroup) => {
                    if(workgroup.enterprise == null && this.userId == workgroup.owner_id){
                        return workgroup.title.toLowerCase().match(this.search.toLowerCase())
                    }
                })
            } else if(this.isParticipant == true){
                return this.workList.filter((workgroup) => {
                    if(workgroup.enterprise == null && this.userId != workgroup.owner_id){
                        return workgroup.title.toLowerCase().match(this.search.toLowerCase())
                    }
                })
            } else {
                return this.workList.filter((workgroup) => {
                    if(workgroup.enterprise == null){
                        return workgroup.title.toLowerCase().match(this.search.toLowerCase())
                    }
                })
            }
            // return this.testListWg.filter((workgroup) => {
            //     if(workgroup.isEnterprise == false){
            //         return workgroup.title.toLowerCase().match(this.search.toLowerCase())
            //     }
            // })
        },
        filteredWorkgroupEnterprise(){
            if(this.isOwner == true){
                return this.workList.filter((workgroup) => {
                    if(workgroup.enterprise != null && this.userId == workgroup.owner_id){
                        return workgroup.title.toLowerCase().match(this.search.toLowerCase())
                    }
                })
            } else if(this.isParticipant == true){
                return this.workList.filter((workgroup) => {
                    if(workgroup.enterprise != null && this.userId != workgroup.owner_id){
                        return workgroup.title.toLowerCase().match(this.search.toLowerCase())
                    }
                })
            } else {
                return this.workList.filter((workgroup) => {
                    if(workgroup.enterprise != null){
                        return workgroup.title.toLowerCase().match(this.search.toLowerCase())
                    }
                })
            }
            // return this.testListWg.filter((workgroup) => {
            //     if(workgroup.isEnterprise == true){
            //         return workgroup.title.toLowerCase().match(this.search.toLowerCase())
            //     }
            // })
        },
        filteredWgOwner() {
            var userId = this.$store.getters['user/id']
            return this.workList.filter((workgroup) => {
                if(this.isOwner == true && workgroup.owner_id == userId){
                    return workgroup.title.toLowerCase().match(this.search.toLowerCase())
                }
            })
        },
        sumFavoriteWorkgroup(){
            return this.workList.filter((workgroup) => {
                if(workgroup.favorite == true){
                    return workgroup
                }
            })
        }
    },
    methods: {
        handleOutsideClick(e){
            if (!e.target.closest(".img-wg-more")) {
                this.activeDropdown = null;
            }
        },
        toggleDropdown(itemId) {
            this.activeDropdown = this.activeDropdown === itemId ? null : itemId;
        },
        showAllWg(){
            this.isOwner = false
            this.isParticipant = false
        },
        ownerWg(){
            this.isOwner = true
            this.isParticipant = false
            this.searchFocus = true
        },
        participantWg(){
            this.isOwner = false
            this.isParticipant = true
            this.searchFocus = true
        },
        focusSearch(){
            this.searchFocus = true
        },
        focusOutSearch(){
            if(this.search == ''){
                this.searchFocus = false
            }
        },
        addFavorite(id){
            if(this.sumFavoriteWorkgroup.length < 5){
                this.$store.dispatch('workgroup/setFavoriteWg',{workgroup_id: id})
                this.activeDropdown = null
            } else {
                this.$root.$emit('bv::show::modal', 'modalAlertMaxWorkgroup')
            }
        },
        removeFavorite(id){
            this.$store.dispatch('workgroup/unFavoriteWg',{workgroup_id: id})
            this.activeDropdown = null
        },
        deleteWorkgroup(id){
            this.$store.dispatch("workgroup/retrieve",id)
            .then((res)=> {
                this.$root.$emit('bv::show::modal', 'modalDeleteWorkgroup')
            })
        },
        leaveWorkgroup(id){
            this.$store.dispatch("workgroup/retrieve",id)
            .then((res)=> {
                this.$root.$emit('bv::show::modal', 'modalLeaveWorkgroup')
            })
        },
        expandSidebar(){
            const firstSidebar = document.getElementById('side-bar-menu')
            const secondSidebar = document.getElementById('side-bar-menu-2')
            const btnExpandSidebar = document.getElementById('expand-side-btn')
            const dropSecondSide = document.querySelectorAll('.dropdown-more-scnd-side')

            const toggleButton = document.getElementById('second-sidebar-toggle')

            const primaryWgList = document.getElementById('primary-wg')
            const enterpriseWgList = document.getElementById('enterprise-wg')

            const imgTogglePrimary = document.getElementById('primary-wg-img-toggle')
            const imgToggleEnterprise = document.getElementById('enterprise-wg-img-toggle')

            firstSidebar.style.opacity = 1
            firstSidebar.style.visibility = 'visible'
            // firstSidebar.style.display = 'block'
            secondSidebar.classList.add('active')
            secondSidebar.style.opacity = 1
            secondSidebar.style.visibility = 'visible'
            // secondSidebar.style.display = 'block'
            secondSidebar.style.left = '227px'
            btnExpandSidebar.style.visibility = 'hidden'
            btnExpandSidebar.style.opacity = 0
            // btnExpandSidebar.style.display = 'none'
            dropSecondSide.forEach((v) => {
                v.style.left = '415px'
            })
            // if(!secondSidebar.classList.contains('active')){
            // }

            document.addEventListener('click', (e) => {
                if (!secondSidebar.contains(e.target) && e.target !== toggleButton) {
                // Using Opacity and Visibility
                if(firstSidebar.style.visibility == 'hidden'){
                    secondSidebar.classList.add('active')
                    toggleButton.classList.add('active')
                    secondSidebar.style.visibility = 'visible'
                    secondSidebar.style.opacity = 1
                    // secondSidebar.style.display = 'block'
                    secondSidebar.style.left = '0px'
                    if(primaryWgList){
                    // primaryWgList.classList.add('active')
                    // enterpriseWgList.classList.add('active')
                    // imgTogglePrimary.style.transform = 'rotate(180deg)'
                    // imgToggleEnterprise.style.transform = 'rotate(180deg)'
                    }
                } else {
                    this.isSecondSidebar = false
                    secondSidebar.classList.remove('active')
                    toggleButton.classList.remove('active')
                    secondSidebar.style.visibility = 'hidden'
                    secondSidebar.style.opacity = 0
                    // secondSidebar.style.display = 'none'
                    if(primaryWgList){
                    // primaryWgList.classList.add('active')
                    // enterpriseWgList.classList.add('active')
                    // imgTogglePrimary.style.transform = 'rotate(0deg)'
                    // imgToggleEnterprise.style.transform = 'rotate(0deg)'
                    }
                }
                }
            })
        },
        openWorkgroup(item){
            this.$ga.event('workgroup', 'actionx', 'labelx', 1)
            this.$store.commit(this.state+"/SET_KEYWORD","");
            this.$store.commit(this.state+"/SET_PARENT_ID","");
            this.$store.commit(this.state+"/DELETE_BREADCUMB",[]);
            this.$store.commit('storage/SET_ITEM', null)
            this.$store.commit(this.state+"/SET_SHOWBREADCUMB",true)
            this.$store.dispatch("workgroup/retrieve",item._id)
            .then(()=>{
                if(this.$router.currentRoute.name != "home"){
                    this.$router.push({name:"home", query: {wgId: item._id}}).catch(()=>{});
                } else {
                    const currentPath = this.$route.path

                    this.$router.push({ path: currentPath, query: {wgId: item._id}})
                    .catch(err => {
                        // Handle the navigation error (redundant navigation)
                        if (err.name !== 'NavigationDuplicated') {
                            throw err;
                        }
                    })
                }
                this.$forceUpdate();
                this.$store.dispatch("workgroup/showDetails");
            });
            this.$store.dispatch('storage/setSidebarDisplay', true)
        },
        clickedStars(){
            alert('Stars')
        },
        clickedMore(){
            // alert('More')
            this.isDropdownWgListVisible = false
        },
        togglePrimaryWg(){
            const primaryWgList = document.getElementById('primary-wg')
            const imgTogglePrimary = document.getElementById('primary-wg-img-toggle')

            if(primaryWgList.classList.contains('active')){
                primaryWgList.classList.remove('active')
                imgTogglePrimary.style.transform = 'rotate(0deg)'
            } else {
                primaryWgList.classList.add('active')
                imgTogglePrimary.style.transform = 'rotate(180deg)'
            }
        },
        toggleEnterpriseWg(){
            const enterpriseWgList = document.getElementById('enterprise-wg')
            const imgToggleEnterprise = document.getElementById('enterprise-wg-img-toggle')

            if(enterpriseWgList.classList.contains('active')){
                enterpriseWgList.classList.remove('active')
                imgToggleEnterprise.style.transform = 'rotate(0deg)'
            } else {
                enterpriseWgList.classList.add('active')
                imgToggleEnterprise.style.transform = 'rotate(180deg)'
            }
        }
    }
}
</script>

<style>

</style>