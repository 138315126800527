<script>
import router from "@/router";

import NavBar from "@/components/nav-bar-new";
import SideBar from "@/components/side-bar";
import SideBar2 from "@/components/side-bar-2";
// import Properties from "../../components/widgets/properties.vue";
// import propertiesShare from "../../components/widgets/propertiesShare.vue";
// import participantsWorkgroup from "../../components/widgets/workgroup/participantList.vue";
import Upload from "../../components/widgets/upload.vue";
// import SidebarRight from '../../components/side-bar-right.vue'
import EncryptNote from '../../components/widgets/encryptNote.vue'
import DecryptNote from '../../components/widgets/decryptNote.vue'
import ToastNotification from '../../components/widgets/toastNotification.vue'

export default {
  components: { NavBar, SideBar,
  SideBar2,
  // Properties,
  // propertiesShare,
  // participantsWorkgroup,
  // SidebarRight,
  ToastNotification,
  DecryptNote,
  EncryptNote,
  Upload },
  data() {
    return {
      isMenuCondensed: false,
    };
  },
  computed : {
    isWorkgroupSuspend() {
      return this.$store.getters['workgroup/statusSuspendWorkgroup']
    },
    isSuspend() {
      return this.$store.getters['user/accountSuspend']
    },
    checkDisplaySidebar () {
      return this.$store.getters['storage/displaySidebar']
    },
    currentRouteName () {
      return this.$route.name
    },
    showProperties(){
        return this.$store.getters['storage/showProperties'];
    },
    showPropertiesShare(){
        return this.$store.getters['share/showProperties'];
    },
    showParticipants(){
        return this.$store.getters['workgroup/showParticipants'];
    },
    state(){
        return this.$store.getters['state/state']
    }
  },
  created: () => {
    document.body.removeAttribute("data-layout", "horizontal");
    document.body.removeAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-layout-size", "boxed");
  },
  mounted()  {
    if(this.state == 'workgroup'){
      const displaySidebarValue = this.checkDisplaySidebar;
      this.$store.dispatch('storage/setSidebarDisplay',displaySidebarValue);
    } else {
      this.$store.dispatch('storage/setSidebarDisplay',false);
    }
  },
  methods: {
      dropHandler(ev){

          // Prevent default behavior (Prevent file from being opened)
          ev.preventDefault();

          // if (ev.dataTransfer.files) {
          //   // Use DataTransferItemList interface to access the file(s)
          //   for (var i = 0; i < ev.dataTransfer.files.length; i++) {
          //     // If dropped items aren't files, reject them
          //     if (ev.dataTransfer.files[i].kind === 'file') {
          //       var file = ev.dataTransfer.files[i].getAsFile();
          //     }
          //   }
          // } else {
            // Use DataTransfer interface to access the file(s)
          // }
      },
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    }
  }
};
</script>
<style>
.pad-right-0{
  padding-right: 0 !important;
}
</style>


<template>
  <div id="layout-wrapper"
    style="display: flex; flex-direction: column; height: 100%;"
    @dragover.prevent @drop.prevent
  >
    <SideBar ref="sidebar" :is-condensed="isMenuCondensed" />
    <SideBar2/>
    <NavBar />
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="main-content" id="content-pages" :style="currentRouteName != 'Setting' && 
      currentRouteName != 'workgroupSetting' && 
      currentRouteName != 'FileShared' &&
      currentRouteName != 'SettingsOverview' &&
      currentRouteName != 'SettingsPlan' &&
      currentRouteName != 'EnterpriseAdminOverview' &&
      currentRouteName != 'EnterpriseAdminMember' &&
      currentRouteName != 'EnterpriseAdminCustomize' &&
      currentRouteName != 'EnterpriseAdminPlan' &&
      currentRouteName != 'WorkgroupSettings' &&
      currentRouteName != 'WorkgroupParticipants' &&
      currentRouteName != 'trash' &&
      (isSuspend != true && state == 'storage') ||
      (isWorkgroupSuspend != true && state == 'workgroup' && currentRouteName != 'WorkgroupSettings' && currentRouteName != 'WorkgroupParticipants')
      ? 'margin-right: 350px;' : 'margin-right: 0'">
    <!-- <div class="main-content" id="content-pages" style="margin-right: 50px;"> -->
      <!-- <div class="page-content" id="content-pages"> -->
        <!-- Start Content-->
        <!-- <div class="container-fluid pad-right-0" id="main-page"> -->
          <slot />
        <!-- </div> -->
      <!-- </div> -->
      <!-- <Footer /> -->
    </div>
    <!-- <SidebarRight/> -->
    
    <div id="overlayDropFile">
    <input id="fileUpload" type="file">
      <div class="d-flex justify-content-center align-content-center">
        <p>hell</p>
      </div>
    </div>
      <!-- <Properties v-show="showProperties" /> -->
      <!-- <participantsWorkgroup v-show="showParticipants" /> -->
      <!-- <propertiesShare v-show="showPropertiesShare" /> -->
    
    <ToastNotification/>
    
    <div class="d-flex justify-content-end align-items-end" style="position: absolute; bottom: 0; right: 0; z-index:99999">
      <div>
        <EncryptNote/>
      </div>
      <div>
        <DecryptNote/>
      </div>
      <div>
        <Upload/>
      </div>
    </div>


  </div>
</template>
