<template>
    <div
        style="position: fixed;
        z-index: 10
        top: 30px;
        right: 20px;
        padding-inline-start: 0;">
        <ul v-for="(list, idx) in listToast" :key="idx" id="toast-notification">
            <li v-if="list.group_by == 'workgroup'" class="toast-list-notif bg-toast-info">
                <div class="img-info-toast">
                    <img src="@/assets/images/icon/icon-info-notif.svg" alt="">
                </div>
                <div class="desc-notif-toast">
                    <div class="title-notif-toast">
                        <span>{{list.title}}</span>
                    </div>
                    <div class="sub-title-notif-toast">
                        <span>{{list.message}} Please check your email to join.</span>
                    </div>
                    <!-- <div @click="joinWg(list, idx)" class="btn-desc-notif-toast mt-3">
                        <span>Join now</span>
                    </div> -->
                </div>
                <div class="img-cross-toast">
                    <img src="@/assets/images/icon/icon-cross-toast.svg" class="cursor-pointer" alt="" @click="close(idx)">
                </div>
            </li>
            <li v-else-if="list.group_by == 'workgroup_info'" class="toast-list-notif bg-toast-info">
                <div class="img-info-toast">
                    <img src="@/assets/images/icon/icon-info-notif.svg" alt="">
                </div>
                <div class="desc-notif-toast">
                    <div class="title-notif-toast">
                        <span>{{list.title}}</span>
                    </div>
                    <div class="sub-title-notif-toast">
                        <span>{{list.message}}</span>
                    </div>
                    <!-- <div v-if="list.title != 'Workgroup deleted'" class="btn-desc-notif-toast mt-3">
                        <span>View details</span>
                    </div> -->
                </div>
                <div class="img-cross-toast">
                    <img src="@/assets/images/icon/icon-cross-toast.svg" class="cursor-pointer" alt="" @click="close(idx)">
                </div>
            </li>
            <li 
                v-else-if="list.group_by == null"
                class="toast-list-notif"
                :class="list.encrypt.code ? (list.encrypt.code == 2 || list.encrypt.code == 5 ? 'bg-toast-secure-success' : 'bg-toast-secure-failed') : (list.encrypt == 2 || list.encrypt == 5 ? 'bg-toast-secure-success' : 'bg-toast-secure-failed')">
                <div class="img-info-toast">
                    <img v-if="list.encrypt.code ? list.encrypt.code == 2 || list.encrypt.code == 5 : list.encrypt == 2 || list.encrypt == 5" src="@/assets/images/icon/secure-success.svg" alt="">
                    <img v-else src="@/assets/images/icon/secure-failed.svg" alt="">
                </div>
                <div class="desc-notif-toast">
                    <div class="title-notif-toast">
                        <span>{{list.title}}</span>
                    </div>
                    <div class="sub-title-notif-toast">
                        <span>{{list.message}} <span v-if="list.workgroup != null && list.workgroup != ''">in the</span> {{list.workgroup}} <span v-if="list.enterprise != null && list.enterprise != ''">at</span> {{list.enterprise}}</span>
                    </div>
                </div>
                <div class="img-cross-toast">
                    <img v-if="list.encrypt.code ? list.encrypt.code == 2 || list.encrypt.code == 5 : list.encrypt == 2 || list.encrypt == 5" src="@/assets/images/icon/icon-cross-toast-success.svg" class="cursor-pointer" alt="" @click="close(idx)">
                    <img v-else src="@/assets/images/icon/icon-cross-toast-failed.svg" class="cursor-pointer" alt="" @click="close(idx)">
                </div>
            </li>
            <li v-else-if="list.group_by == 'File shared'" class="toast-list-notif bg-toast-info">
                <div class="img-info-toast">
                    <img src="@/assets/images/icon/icon-info-notif.svg" alt="">
                </div>
                <div class="desc-notif-toast">
                    <div class="title-notif-toast">
                        <span>{{list.title}}</span>
                    </div>
                    <div class="sub-title-notif-toast">
                        <span>{{list.message}}</span>
                    </div>
                </div>
                <div class="img-cross-toast">
                    <img src="@/assets/images/icon/icon-cross-toast.svg" class="cursor-pointer" alt="" @click="close(idx)">
                </div>
            </li>
            <li v-else class="toast-list-notif bg-toast-info">
                <div class="img-info-toast">
                    <img src="@/assets/images/icon/icon-info-notif.svg" alt="">
                </div>
                <div class="desc-notif-toast">
                    <div class="title-notif-toast">
                        <span>{{list.title}}</span>
                    </div>
                    <div class="sub-title-notif-toast">
                        <span>{{list.message}}</span>
                    </div>
                    <!-- <div class="btn-desc-notif-toast mt-3">
                        <span>View details</span>
                    </div> -->
                </div>
                <div class="img-cross-toast">
                    <img src="@/assets/images/icon/icon-cross-toast.svg" class="cursor-pointer" alt="" @click="close(idx)">
                </div>
            </li>
        </ul>
    </div>
    <!-- <div id="notificationToast">
        <div class="notif-toast">
            <div class="img-info-toast">
                <img src="@/assets/images/icon/icon-info-notif.svg" alt="">
            </div>
            <div class="desc-notif-toast">
                <div class="title-notif-toast">
                    <span>1 new message</span>
                </div>
                <div class="sub-title-notif-toast">
                    <span>Budi Finance sent a new message to you in the UI UX Design workgroup at DesktopIP enterprise.</span>
                </div>
                <div class="btn-desc-notif-toast mt-3">
                    <span>View details</span>
                </div>
            </div>
            <div class="img-cross-toast">
                <img src="@/assets/images/icon/icon-cross-toast.svg" class="cursor-pointer" alt="" @click="close()">
            </div>
        </div>
    </div> -->
</template>

<script>
export default {
    computed:{
        listToast() {
            return this.$store.getters['notif/toastNotifs']
        },
    },
    methods:{
        close(idx){
            this.$store.commit('notif/REMOVE_TOAST_NOTIF', idx)
        },
        joinWg(item, idx){
            var payload = {
                user_id: item.user_id,
                workgroup_id: item.workgroup_id
            }
            this.$store.dispatch('workgroup/joinWg', payload)
            .then((result)=>{
                this.$store.dispatch("workgroup/retrieve",result._id)
                .then(()=>{
                    this.$router.push({ path: '/', query: {wgId: result._id}})
                    this.$forceUpdate();
                    this.$store.dispatch("workgroup/showDetails");
                    this.$store.commit('notif/REMOVE_TOAST_NOTIF', idx)
                })
            })
        }
    }
}
</script>

<style>

</style>