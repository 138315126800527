var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","flex-direction":"column","height":"100%"},attrs:{"id":"layout-wrapper"},on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();}}},[_c('SideBar',{ref:"sidebar",attrs:{"is-condensed":_vm.isMenuCondensed}}),_c('SideBar2'),_c('NavBar'),_c('div',{staticClass:"main-content",style:(_vm.currentRouteName != 'Setting' && 
    _vm.currentRouteName != 'workgroupSetting' && 
    _vm.currentRouteName != 'FileShared' &&
    _vm.currentRouteName != 'SettingsOverview' &&
    _vm.currentRouteName != 'SettingsPlan' &&
    _vm.currentRouteName != 'EnterpriseAdminOverview' &&
    _vm.currentRouteName != 'EnterpriseAdminMember' &&
    _vm.currentRouteName != 'EnterpriseAdminCustomize' &&
    _vm.currentRouteName != 'EnterpriseAdminPlan' &&
    _vm.currentRouteName != 'WorkgroupSettings' &&
    _vm.currentRouteName != 'WorkgroupParticipants' &&
    _vm.currentRouteName != 'trash' &&
    (_vm.isSuspend != true && _vm.state == 'storage') ||
    (_vm.isWorkgroupSuspend != true && _vm.state == 'workgroup' && _vm.currentRouteName != 'WorkgroupSettings' && _vm.currentRouteName != 'WorkgroupParticipants')
    ? 'margin-right: 350px;' : 'margin-right: 0'),attrs:{"id":"content-pages"}},[_vm._t("default")],2),_vm._m(0),_c('ToastNotification'),_c('div',{staticClass:"d-flex justify-content-end align-items-end",staticStyle:{"position":"absolute","bottom":"0","right":"0","z-index":"99999"}},[_c('div',[_c('EncryptNote')],1),_c('div',[_c('DecryptNote')],1),_c('div',[_c('Upload')],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"overlayDropFile"}},[_c('input',{attrs:{"id":"fileUpload","type":"file"}}),_c('div',{staticClass:"d-flex justify-content-center align-content-center"},[_c('p',[_vm._v("hell")])])])
}]

export { render, staticRenderFns }