<template>
    <b-modal id="modal-switch-domain" no-close-on-backdrop hide-header hide-footer centered>
        <div class="d-flex flex-column">
            <img src="@/assets/images/switch-subdomain.svg" class="mb-3" alt="">
            <span class="mt-3 switch-subdomain-title">Switch account?</span>
            <span class="my-3 switch-subdomain-text">
                You will switch to {{switchSubdomain != null ? 'an enterprise' : 'a primary'}} account in a new tab of your browser.
            </span>
            <div class="d-flex justify-content-end align-items-center mt-3 switch-subdomain-btn">
                <span class="mr-3" @click="close()">Cancel</span>
                <button class="ml-3 " @click="openNewSubdomain()">Switch</button>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    computed: {
        switchSubdomain(){
            return this.$store.getters["enterprise/switchSubdomain"]
        }
    },
    methods: {
        openNewSubdomain(){
            if(this.switchSubdomain != null){
                this.$store.dispatch('enterprise/getToken', this.switchSubdomain)
                .then(()=>{
                    this.$bvModal.hide('modal-switch-domain')
                })
            } else {
                window.open(`${process.env.VUE_APP_MAIN_DOMAIN}`, '_blank')
            }
        },
        close(){
            this.$bvModal.hide('modal-switch-domain')
        }
    }
}
</script>

<style>

</style>