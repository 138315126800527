<template>
    <b-modal id="modal-enterprise-info" no-close-on-backdrop hide-header hide-footer centered>
        <div class="d-flex flex-column">
            <span class="enterprise-text-desc">Enterprise infomation</span>
            <span class="my-3 enterprise-member-list-text">The information of DesktopIP Limited enterprise.</span>
            <div class="d-flex justify-content-between my-3">
                <span class="enterprise-text-desc">Your email</span>
                <input
                    disabled
                    class="input-info-enterprise"
                    v-model="email"
                    type="text">
            </div>
            <div class="d-flex justify-content-between mb-3">
                <span class="enterprise-text-desc">First name</span>
                <input
                    disabled
                    class="input-info-enterprise"
                    v-model="firstName"
                    type="text">
            </div>
            <div class="d-flex justify-content-between mb-3">
                <span class="enterprise-text-desc">Last name</span>
                <input
                    disabled
                    class="input-info-enterprise"
                    v-model="lastName"
                    type="text">
            </div>
            <div>
                <hr style="border: 1px solid #E9EAEA; margin: 8px 0;">
            </div>
            <div class="d-flex justify-content-between my-3">
                <span class="enterprise-text-desc">Enterprise name</span>
                <input
                    disabled
                    class="input-info-enterprise"
                    v-model="infoEnterprise.company_name"
                    type="text">
            </div>
            <div class="d-flex justify-content-between mb-3">
                <span class="enterprise-text-desc">Enterprise admin</span>
                <input
                    disabled
                    class="input-info-enterprise"
                    v-model="infoEnterprise.owner"
                    type="text">
            </div>
            <div class="d-flex justify-content-end align-items-center mt-3 close-and-submit-enterprise">
                <button
                    @click="close()"
                    class="ml-3"
                >
                    Got it
                </button>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    computed: {
        infoEnterprise(){
            return this.$store.getters['enterprise/enterpriseInfo']
        },
        email() {
            return this.$store.getters['user/email']
        },
        firstName() {
            return this.$store.getters['user/firstName']
        },
        lastName() {
            return this.$store.getters['user/lastName']
        },
    },
    methods: {
        close() {
            this.$bvModal.hide('modal-enterprise-info')
        }
    }
}
</script>

<style>

</style>