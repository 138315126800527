<template>
  <div id="decryptLoading">
    <div class="d-flex justify-content-between align-content-center px-4">
        <p class="mb-4 bold-normal default-text-style color-dark">Decrypting {{decryptProcess.length}} item</p>
        <div class="d-flex">
          <span class="cursor-pointer" @click="showHideDecrypt()">
            <img src="@/assets/images/icon/chevDown.svg" alt width="100%" />
          </span>
          <span class="cursor-pointer ml-3" @click="close()">
            <img src="@/assets/images/icon/close-secure.svg" alt="">
          </span>
        </div>
    </div>
    <div v-if="!hideDecrypt">
        <div class="info-decrypt-notif">
          <!-- <pre>{{firebaseProcess}}</pre> -->
          <div class="horizontal-modal-line"></div>
          <div class="d-flex justify-content-between align-content-center flex-column mb-4 px-4" v-for="file in decryptProcess" :key="file._id">
            <div class="d-flex justify-content-between align-content-center">
              <div class="d-flex align-items-center justify-items-center file-name-secure">
                <img :src="require(`@/assets/images/icon/${getImage(file.file_name)}`)" alt height="100%" />
                <p class="font-size-16 px-2 mb-0 notif-btm-text" data-toggle="tooltip" :title="file.file_name">{{file.file_name}}</p>
              </div>
              <!-- Code: {{file.encrypt.code}} -->
              <div class="d-flex justify-content-end">
                <div v-show="file.encrypt.code == 4" class="spinner-border hw-16 font-size-14 text-primary" role="status">
                  <!-- <span class="sr-only">Loading...</span> -->
                </div>
                <div v-show="file.encrypt.code == 5">
                  <img src="@/assets/images/icon/success-secure.svg" alt="" data-toggle="tooltip" title="Decrypted successfully">
                </div>
                <div v-show="file.encrypt.code == 6">
                  <img src="@/assets/images/icon/error-secure.svg" alt="" data-toggle="tooltip" title="Decryption failed">
                </div>
                <div v-show="file.encrypt.code == 7">
                  <img src="@/assets/images/icon/error-secure.svg" alt="" data-toggle="tooltip" title="Wrong password. Please try another password.">
                </div>
                <div class="cursor-pointer ml-3" @click="remove(file._id)">
                  <img src="@/assets/images/icon/close-secure.svg" alt="">
                </div>
              </div>
            </div>
            <!-- <div class="d-flex  justify-content-between align-content-center mb-3 item-decrypt">
              <div class="d-flex align-items-center justify-items-center" style="width:90%">
                <img :src="require(`@/assets/images/icon/${getImage(file.file_name)}`)" alt height="100%" />
                <p class="font-size-14 px-2 mb-0 notif-btm-text">{{file.file_name}}</p>
              </div>
              <div>
                <div id="progress-decrypt">
                  <div v-show="file.encrypt.code == 4" class="spinner-border font-size-18 text-primary" role="status"></div>
                  <b-avatar variant="success" size="2rem" v-show="file.encrypt.code == 5"><i class="bx bx-check font-size-18"></i></b-avatar>
                  <b-avatar variant="danger" size="2rem" v-show="file.encrypt.code == 6"><i class="bx bx-x-circle font-size-18"></i></b-avatar>
                  <b-avatar variant="danger" size="2rem" v-show="file.encrypt.code == 7"><i class="bx bx-info-circle font-size-18"></i></b-avatar>
                </div>
                <div class="avatar-xs" @click="remove(file._id)">
                    <span class="avatar-title rounded-circle"><i class="bx bx-x font-size-18"></i></span>
                </div>
              </div>
            </div> -->
            <!-- <p v-if="file.encrypt.code == 4" class="text-muted ml-2">Decryption is in progress</p>
            <p v-if="file.encrypt.code == 5" class="text-muted ml-2">Decrypted successfully</p>
            <p v-if="file.encrypt.code == 6" class="text-muted ml-2">Decryption failed</p>
            <p v-if="file.encrypt.code == 7" class="text-muted ml-2">Wrong password. Please try another password.</p> -->
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import Storage from "../../api/storage";

export default {
    name: 'DecryptNote',
    data() {
        return {
            hideDecrypt: false,
            percentage: 0
        }
    },
    computed: {
        firebaseProcess() {
            return this.$store.getters["decrypt/notifDecrypt"]
        },
        decryptProcess() {
            return this.$store.getters["decrypt/progress"]
        }
    },
    methods: {
        close() {
            this.$store.commit("decrypt/CLEAR")
            this.$store.commit("decrypt/RESET_PROGRES")
            document.getElementById("decryptLoading").style.display = "none";
        },
        showHideDecrypt(){
            this.hideDecrypt = !this.hideDecrypt
        },
        remove(id){
            if(this.decryptProcess.length <= 1){
                this.$store.commit("decrypt/CLEAR")
                document.getElementById("decryptLoading").style.display = "none";
            }
            this.$store.commit("decrypt/REMOVE",id);
        },
        getImage(filename){
            var storageUtils = new Storage();
            return storageUtils.getImage(filename.substr(filename.lastIndexOf('.') + 1))
        }
    },
}
</script>

<style>

</style>